<template>
    <v-app>
        <div class="card card-custom gutter-b" v-if="!loading">
            <div class="card-body">
                <!--begin::Details-->
                <v-row>
                    <div class="d-flex mb-9">
                        <!--begin::Info-->
                        <div class="flex-grow-1">
                            <!--begin::Content-->
                            <div class="d-flex flex-wrap justify-content-between">
                                <div class="d-flex flex-column flex-grow-1">
                                    <p v-if="submission" v-html="submission.assignment_text"
                                       class="font-weight-bold text-dark-50 ml-5">
                                    </p>

                                    <v-row v-if="submission && submission.attachment && submission.attachment.length">
                                        <v-col cols="12">
                                            <h6>Attachments <span class="badge badge-success"> {{submission.attachment.length}}</span></h6>

                                        </v-col>
                                        <v-col cols="12">
                                            <div class="row media-gallery" v-if="submission.attachment.length">
                                                <div class="col-md-12 m-1 preview-images" v-for="(image, index) in submission.attachment" :key="index">
                                                    <img
                                                            v-if="checkFileType(image.title)"
                                                            :src="image.path"
                                                            :alt="`Image Uploader ${index}`"
                                                    />
                                                    <img v-else-if="image.file_type=='pdf'" src="/media/svg/files/pdf.svg"
                                                         :alt="`Image Uploader ${index}`" style="height: 24px; text-align: left"/>
                                                    <img v-else-if="image.file_type=='excel'" src="/media/svg/files/csv.svg"
                                                         :alt="`Image Uploader ${index}`" style="height: 24px; text-align: left"/>
                                                    <img v-else-if="image.file_type=='doc'" src="/media/svg/files/doc.svg"
                                                         :alt="`Image Uploader ${index}`" style="height: 24px; text-align: left"/>
                                                    <img v-else src="/media/svg/files/xml.svg" :alt="`Image Uploader ${index}`" style="height: 24px; text-align: left"/>
                                                    <span
                                                            class=""
                                                    >
                            <a :href="image.path" target="_blank" class="ml-3">
                                  <span class="name">{{ image.title }}</span>
                                <!--                            <i class="fas fa-eye blue-grey&#45;&#45;text" title="View"></i>-->
                          </a>
                        </span>

                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                            <!--end::Content-->
                        </div>
                        <!--end::Info-->
                    </div>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <a
                                href="#"
                                v-if="submission"
                                class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                        >{{submission.student.full_name}}</a
                        >
                        <div class="my-lg-0 my-3" v-if="submission">
                            <strong v-if="submission.submitted_date">Submitted At :
                                {{submission.submitted_at}}</strong>
                        </div>
                        <div class="my-lg-0 my-3" v-if="submission">
                            <strong v-if="submission.graded_by">Graded By :
                                {{submission.graded_by_user.full_name}} at  {{submission.graded_date_formated}}</strong>
                        </div>

                    </v-col>
                    <v-col cols="6" v-if="marking">
                        <v-text-field
                                v-model="marking.mark_obtained"
                                type="number"
                                outlined
                                dense
                                :suffix="`/${assignment.total_marks}`"
                                label="Obtain Marks"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-select
                                :items="status"
                                v-model="marking.status"
                                item-value="value"
                                item-text="title"
                                outlined
                                dense
                                label="Status"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <label for="">Feedback Comments</label>
                        <ckeditor v-model="marking.feed_back"></ckeditor>
                    </v-col>

                    <div class="col-12 mt-5">
                        <div class="float-right">
                            <v-btn
                                    class="btn btn-standard text-gray"
                                    depressed
                            >Cancel
                            </v-btn>
                            <v-btn
                                    class="text-white ml-2 btn btn-primary"
                                    depressed
                                    @click="createAndUpdate"
                                    :loading="loading"
                            >Save
                            </v-btn>
                        </div>
                    </div>
                </v-row>
                <!--end::Details-->

            </div>
        </div>
        <div class="col-md-12" v-else>
            <v-skeleton-loader
                    class="mx-auto"
                    type="table"
            ></v-skeleton-loader>
        </div>
    </v-app>
</template>

<script>
    import {AssignmentMixin} from "@/mixins/AssignmentMixin";
    import AssignmentSubmissionService from "@/core/services/assignement/AssignmentSubmissionService";
    import AssignmentMarkingService from "@/core/services/assignement/AssignmentMarkingService";

    const assignmentMarkingService = new AssignmentMarkingService();

    const assignmentSubmissionService = new AssignmentSubmissionService();

    export default {
        name: "AssignmentSubmissionDetail",
        props:['studentId', 'assignmentId'],
        data() {
            return {
                marking: {
                    mark_obtained: null,
                    status: null,
                    feed_back: null,
                    student_id: null,
                    assignment_id: null,
                    assignments_submission_id: null,
                },
                submission: null,
                loading: true,
                imageFiles: ["jpg", "jpeg", "png", "gif"],
                status: [
                    {
                        title: 'Submitted',
                        value: 'submitted'
                    },
                    {
                        title: 'Pending',
                        value: 'pending'
                    },
                    {
                        title: 'Rejected',
                        value: 'rejected'
                    },
                    {
                        title: 'ReSubmited',
                        value: 'resubmited'
                    },
                    {
                        title: 'Graded',
                        value: 'graded'
                    },
                    {
                        title: 'Flagged',
                        value: 'flagged'
                    },


                ]
            }
        },
        mixins: [AssignmentMixin],
        computed: {
            assignment_id() {
                return this.assignmentId;
            },
            student_id() {
                return this.studentId;
            },
            currentUser() {
                return this.$store.getters.currentUser;
            },

        },
        mounted() {
            this.getSingleAssignment(this.assignment_id);
            this.getByAssignmentSubmission();
            this.marking.assignment_id = this.assignment_id;
            this.marking.student_id = this.student_id;
        }, methods: {
            getByAssignmentSubmission() {
                assignmentSubmissionService.getByAssignmentAndStudentId(this.assignment_id, this.student_id).then(response => {

                    if(response.data.submission!=null){
                      this.submission = response.data.submission;
                      this.marking.assignments_submission_id = this.submission.id;
                      this.getByAssignmentMarking()
                    }
                    this.loading = false;
                });
            },
            getByAssignmentMarking() {
                assignmentMarkingService.getByAssignmentAndStudentId(this.assignment_id, this.student_id).then(response => {
                    this.marking = response.data.marking;
                    this.marking.assignments_submission_id = this.submission.id;
                });
            },
            result() {
                let obtain_mark = this.marking.mark_obtained;
                let pass_mark = this.assignment.pass_marks;
                if (obtain_mark >= pass_mark) {
                    return "pass";
                } else return "fail";
            },

            createAndUpdate() {
                this.marking.result = this.result();
                let fd = null;
                if (this.marking.feed_back_attachment)
                    fd = this.generateFd();
                else
                    fd = this.marking;
                if (this.marking.id) {
                    this.__update(fd);
                } else {
                    this.__create(fd);
                }
                // }
            },
            __create(fd) {
                assignmentMarkingService.store(this.assignment_id, fd).then(response => {
                    this.getByAssignmentMarking();
                    this.$snotify.success("Assignment updated");
                    this.loading = false;
                }).catch((err) => {
                    // console.log(err)
                }).finally(() => {
                    this.loading = false;
                });
            },
            __update(fd) {
                assignmentMarkingService.update(this.assignment_id, this.marking.id, fd).then(response => {

                    this.getByAssignmentMarking();
                    this.$snotify.success("Information updated");

                }).catch((err) => {
                    // console.log(err)
                }).finally(() => {
                });
            },

            generateFd() {
                let fd = new FormData();
                for (let key in this.marking) {
                    if (
                        key == "feed_back_attachment" &&
                        this.marking["feed_back_attachment"] &&
                        this.marking["feed_back_attachment"] != null
                    ) {
                        fd.append("feed_back_attachment", this.marking[key]);
                    } else {
                        if (this.marking[key] != null) {
                            fd.append(key, this.marking[key]);
                        }
                    }
                }
                return fd;
            },
            checkFileType(file) {
                if(file!=undefined){
                    let fileExtension = file.split(".")[file.split(".").length-1];
                    let image = this.imageFiles.indexOf(fileExtension) > -1;
                    return image;
                }
                return null;
            },
        },
    }
</script>
<style scoped>

</style>
